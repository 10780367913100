import {clearRefinements} from 'instantsearch.js/es/widgets';

export const clearFilters = (container, title = 'Очистить') => {

	return clearRefinements({
		container: container,
		templates: {
			resetLabel() {
				return `<div class="btn btn-line">${title}</div>`
			},
		},
	});

}