import {sortBy as sortByWidget} from 'instantsearch.js/es/widgets';
import {connectSortBy} from "instantsearch.js/es/connectors";

// Create the render function
const renderSortBy = (renderOptions, isFirstRender) => {
	const {
		options,
		currentRefinement,
		refine,
		widgetParams,
		canRefine,
	} = renderOptions;

	const container = document.querySelector(widgetParams.container);

	if (isFirstRender) {
		const select = document.createElement('select');
		select.classList.add('js-select')
		container.appendChild(select);
	}

	const select = container.querySelector('select');

	select.disabled = !canRefine;

	select.innerHTML = `
    ${options
		.map(
			option => `
          <option
            value="${option.value}"
            ${option.value === currentRefinement ? 'selected' : ''}
          >
            ${option.label}
          </option>
        `
		)
		.join('')}
  `;

	if (isFirstRender) {
		$(document).on('change', widgetParams.container + ' select', (event) => {
			refine(event.target.value);
		});
	}


};

// Create the custom widget
const customSortBy = connectSortBy(renderSortBy);


export const sortBy = customSortBy({
	container: '[data-widget="sort-by"]',
	items: [
		{
			label: 'По названию (а-я)',
			value: 'goods:name:asc',
		},
		{
			label: 'По названию (я-а)',
			value: 'goods:name:desc',
		},
		{
			label: 'Сначала недорогие',
			value: 'goods:price_discount:asc',
		},
		{
			label: 'Сначала дорогие',
			value: 'goods:price_discount:desc',
		},
	]
});
