import {validate_form} from "../functions/validate_form";

$(function () {

	if ($(".js-datepicker").length > 0) {
		$(".js-datepicker input").datepicker({
			changeMonth: true,
			changeYear: true,
			monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
			monthNamesShort: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
			dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
			dateFormat: "dd.mm.yy",
			onSelect: function () {
				let step = $(this).closest('.cart-steps');

				if (step.length > 0) {
					validate_form($(this).closest('form'), false);
				}
			}
		});
	}

});