const searchBar = document.getElementById("topSearch");
const searchBtn = document.querySelector(".btn__search");
const searchInput = document.querySelector(".top-search__input");
const searchWrapper = document.querySelector(".top-search__wrapper");

if(searchBtn)
searchBtn.addEventListener("click", () => {
  searchBar.classList.add("top-search__container--expanded");
  searchWrapper.classList.add("top-search__wrapper--wider");
  searchInput.focus();
});

window.addEventListener("click", (e) => {
  const target = e.target;
  if (!target.closest("#topSearch") && searchBar) {
    searchBar.classList.remove("top-search__container--expanded");
    searchWrapper.classList.remove("top-search__wrapper--wider");
    document.getElementById("topSearchInput").value = "";
    searchBar.blur();
  }
});

