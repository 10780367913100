if (document.querySelector(".decor-nav", ".decor-nav__item")) {
  window.addEventListener("scroll", () => {
    let scrollDistance = window.scrollY;

    document.querySelectorAll("section").forEach((el, i) => {
      if (
        el.offsetTop + 100 - document.querySelector(".decor-nav").clientHeight <= scrollDistance &&
        scrollDistance <= el.offsetTop + el.clientHeight
      ) {
        const decorNavItems = document.querySelectorAll(".decor-nav__item");

        if (decorNavItems[i] && decorNavItems[i].classList.contains("decor-nav__item--active")) {
          return false;
        }

        let left_need_scroll = 0;

        decorNavItems.forEach((el, key) => {
          if (key < i) {
            left_need_scroll += el.clientWidth;
          }
          if (el.classList.contains("decor-nav__item--active")) {
            el.classList.remove("decor-nav__item--active");
          }
        });

        if (decorNavItems[i] && !decorNavItems[i].classList.contains("decor-nav__item--active")) {
          decorNavItems[i]?.classList.add("decor-nav__item--active");

          let width = document.querySelector('.decor-nav').clientWidth;

          if (left_need_scroll - width > 0) {
            decorNavItems[i].closest(".decor-nav-wrap__left").scrollLeft = left_need_scroll - width + decorNavItems[i].clientWidth;
          } else {
            decorNavItems[i].closest(".decor-nav-wrap__left").scrollLeft = 0;
          }

        }
      }
    });
  });
}
