import instantsearch from 'instantsearch.js';
import {instantMeiliSearch} from "@meilisearch/instant-meilisearch";

import {
	configuration,
	pagination,
	widgetPrice,
	widgetPriceInput,
	products,
	searchBox,
	sortBy,
	Refinements,
	widgetList,
	resultsNumberModal,
	clearFilters,
	hierarchicalMenuWidget,
        widgetToggleRefinement
} from './widgets';


const hasBlock = (query) => {
	return $(query).length > 0
}

const search = instantsearch({
	indexName: "goods",
	routing: true,
	searchClient: instantMeiliSearch(
		"https://goods.vkvadrate.ru",
		"d1c05c071ec2bb2136aaf668302314894075b658a57480c8e3787503ac2a9d88",
		{
			finitePagination: true
		}
	).searchClient
});


//В этом массиве мы собираем виджеты для инициализации
let widgetArray = [];

//Функция добавления виджета со всеми проверками
const addWidget = (type, params) => {
	if (!hasBlock(params.name)) return false;
	let widget = false;

	switch (type) {
		case "Refinements":
			widget = Refinements(params.name)
			break;
		case "widgetList":
			widget = widgetList(params.title, params.name, params.attribute, params.search, params.search_placeholder, params.show_more)
			break;
                case "widgetToggleRefinement":
			widget = widgetToggleRefinement(params.title, params.name, params.attribute, params.on, params.off)
			break;
		case "widgetPrice":
			widget = widgetPrice(params.name, params.attribute)
			break;
		case "widgetPriceInput":
			widget = widgetPriceInput(params.name, params.attribute)
			break;
		case "hierarchicalMenuWidget":
			widget = hierarchicalMenuWidget(params.name, params.title);
			break;
		case "clearFilters":
			widget = clearFilters(params.name, params.title);
			break;
		case "pagination":
                    widget = false;
                    if($('[data-widget="pagination"]').length > 0){
			widget = pagination;
                    }
                    break;
	}

	if (!widget) return false;
	widgetArray.push(widget);
}



addWidget('Refinements', {name: "[data-widget='current-refinements']"});
addWidget('widgetList', {name: "[data-widget='categories']", title: 'Категории', attribute: 'catalog_name'});
addWidget('widgetList', {name: "[data-widget='brands']", title: 'Бренд', attribute: 'brand'});
addWidget('widgetList', {name: "[data-widget='collection']", title: 'Коллекции', attribute: 'collection', search: true, search_placeholder: "Поиск по коллекциям", show_more: true});
addWidget('widgetList', {name: "[data-widget='colors']", title: 'Цвет', attribute: 'colors'});
addWidget('widgetList', {name: "[data-widget='laying_method']", title: 'Способ укладки', attribute: 'laying_method'});
addWidget('widgetList', {name: "[data-widget='wear_class']", title: 'Класс износостойкости', attribute: 'wear_class'});
addWidget('widgetList', {name: "[data-widget='сountry_of_origin']", title: 'Страна производства', attribute: 'сountry_of_origin'});
addWidget('widgetList', {name: "[data-widget='design_type']", title: 'Вид дизайна', attribute: 'design_type'});

addWidget('widgetPrice', {name: "[data-widget='price-range']", attribute: 'price'});
addWidget('widgetPriceInput', {name: "[data-widget='price-input']", attribute: 'price'});
//addWidget('hierarchicalMenuWidget', {name: "[data-widget='hierarchical-menu']", title: 'Каталог'});
addWidget('clearFilters', {name: '[data-widget="clear-filters"]', title: 'Сбросить все фильтры'});
addWidget('pagination', {name:'[data-widget="pagination"]'})

//Клон для модалки
//addWidget('hierarchicalMenuWidget', {name: "[data-widget='modal_hierarchical-menu']", title: 'Каталог'});
//coll-1
addWidget('Refinements', {name: "[data-widget='modal_current-refinements']"});
addWidget('widgetList', {name: "[data-widget='modal_categories']", title: 'Категория', attribute: 'catalog_name'});
addWidget('widgetList', {name: "[data-widget='modal_brands']", title: 'Бренд', attribute: 'brand'});
addWidget('widgetList', {name: "[data-widget='modal_collection']", title: 'Коллекции', attribute: 'collection', search: true, search_placeholder: "Поиск по коллекциям"});
addWidget('widgetList', {name: "[data-widget='modal_сountry_of_origin']", title: 'Страна производства', attribute: 'сountry_of_origin'});
addWidget('widgetList', {name: "[data-widget='modal_wear_class']", title: 'Класс износостойкости', attribute: 'wear_class'});
addWidget('widgetList', {name: "[data-widget='modal_fire_hazard_class']", title: 'Класс пожарной опасности', attribute: 'fire_hazard_class', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_laying_method']", title: 'Способ укладки', attribute: 'laying_method'});
// col-2
addWidget('widgetList', {name: "[data-widget='modal_room_type']", title: 'Тип помещение', attribute: 'room_type', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_colors']", title: 'Цвет', attribute: 'colors'});
addWidget('widgetList', {name: "[data-widget='modal_design_type']", title: 'Вид дизайна', attribute: 'design_type'});
addWidget('widgetList', {name: "[data-widget='modal_gloss_level']", title: 'Уровень глянца', attribute: 'gloss_level', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_wood_breed']", title: 'Порода дерева', attribute: 'wood_breed', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_stone_breed']", title: 'Вид камня', attribute: 'stone_breed', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_shade']", title: 'Оттенок', attribute: 'shade', show_more: true});


// col-3
// Параметры ДхШхВ плитки
addWidget('widgetList', {name: "[data-widget='modal_depth_tile']", title: 'Толщина плитки, мм', attribute: 'depth_tile', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_length_tile']", title: 'Длина плитки, мм', attribute: 'length_tile', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_width_tile']", title: 'Ширина плитки, мм', attribute: 'width_tile', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_depth']", title: 'Толщина, мм', attribute: 'depth', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_length']", title: 'Длина, мм', attribute: 'length', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_width']", title: 'Ширина, мм', attribute: 'width', show_more: true});

// col-4
addWidget('widgetList', {name: "[data-widget='modal_embossing_type']", title: 'Вид тиснения', attribute: 'embossing_type', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_chamfer']", title: 'Тип фаски', attribute: 'chamfer', show_more: true});
addWidget('widgetList', {name: "[data-widget='modal_product_use_type']", title: 'Сфера применения', attribute: 'product_use_type', show_more: true});
//addWidget('widgetToggleRefinement', {name: "[data-widget='modal_room_type_wet_set']", title: 'Влагостойкость', attribute: 'room_type_wet_set'});
//addWidget('widgetToggleRefinement', {name: "[data-widget='modal_warm_floors_set']", title: 'Применимость с теплым полом', attribute: 'warm_floors_set'});
addWidget('widgetList', {name: "[data-widget='modal_integrated_backing']", title: 'Интегрированная подложка', attribute: 'integrated_backing', show_more: true});
addWidget('widgetPrice', {name: "[data-widget='modal_price-range']", attribute: 'price'});
addWidget('widgetPriceInput', {name: "[data-widget='modal_price-input']", attribute: 'price'});
addWidget('clearFilters', {name: '[data-widget="modal_clear-filters"]', title: 'Сбросить'});




//addWidget('toggleRefinement', {name: "[data-widget='modal_floor_heating_set']", title: 'Для теплого пола', attribute: 'floor_heating_set'});
addWidget('widgetList', {name: "[data-widget='modal_floor_heating']", title: 'Температура теплого пола', attribute: 'floor_heating'});
//addWidget('toggleRefinement', {name: "[data-widget='modal_site_sliv_set']", title: 'Распродажа', attribute: 'site_sliv_set'});
addWidget('widgetList', {name: "[data-widget='modal_discount']", title: 'Скидка', attribute: 'discount', show_more: true});


addWidget('widgetList', {name: "[data-widget='modal_protective_layer']", title: 'Толщина защитного слоя', attribute: 'protective_layer', show_more: true});



console.log($('.facet-block').length);
if($('.facet-block').length > 0) {
	search.addWidgets([
		...widgetArray,
		configuration,
		resultsNumberModal,
		products,
		searchBox,
		sortBy,
	])
}


export default search;
