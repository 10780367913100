import {svg_init} from "./svg";

$(function () {

	if ($('.widget-slider').length > 0) {
		const autoplayTimeout = 10000;

		let widget_slider = $('.widget-slider').owlCarousel({
			items: 1,
			loop: 1,
			nav: 1,
			dots: 1,
			autoplay: 0,
			autoplayTimeout: autoplayTimeout,
			onInitialized: (e) => {
				const slider = $(e.currentTarget),
					items = e.relatedTarget._items;

				slider.find('.owl-dot').each((key, i) => {
					const name = $(items[key]).find('.front-slider-itm').attr('data-tab-name');

					$(i).attr('aria-label', 'owl button');
					$(i).html("<img src='/skins/img/svg/dot-progress.svg' alt='' class='svg'>")
					svg_init();

					//slider.trigger('play.owl.autoplay', [autoplayTimeout]);
				});
			}, onChanged: (e) => {
				const slider = $(e.currentTarget)

				slider.trigger('stop.owl.autoplay');
				slider.trigger('play.owl.autoplay', [autoplayTimeout]);
			}
		});

	}

	$('.promo-product-table,.t-decor__inner,.t-brand__inner').dataTable({
		info: false,
		pagingType: "numbers",
		language: {
			url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/ru.json',
		}
	});

	$('.js-filter-select').on('input', (e) => {
		let self = $(e.currentTarget),
			val = self.val().toLowerCase()

		$('.js-filter-content').each((k, i) => {
			let text = $(i).text().toLowerCase();

			if (text.indexOf(val) !== -1) {
				$(i).removeClass("hide")
			} else {
				$(i).addClass("hide")
			}
		})

		$('.js-filter-block').each((k, i) => {
			if($(i).find('.js-filter-content:not(.hide)').length > 0){
				$(i).removeClass("hide")
			} else {
				$(i).addClass("hide")
			}
		})

	})
})
