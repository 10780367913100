import {num_format} from "./num_format";

export const cart_calc = () => {
	const nds = 20;

	let items = $('.js-cart-table').last().find('.js-cart-product'),
		all_price = 0,
		all_price_dealer = 0,
                delivery_cost = 0;

	items.each(function () {
                if ($('#delivery-cost').length > 0) {
                    delivery_cost = $('#delivery-cost').val();
                }
		let id = $(this).attr('data-id'),
			item = $('.js-cart-product[data-id=' + id + ']'),
			product_price = parseInt($(this).attr('data-price')),
			count = parseInt($(this).find('.counter input').val()),
                        //delivery_cost = $('#delivery-cost').val(),
			discount = parseFloat($(this).attr('data-discount')),
			discount_val = 0,
			discount_val_dealer = 0,
			base_price = product_price * count,
			base_price_dealer = parseInt($(this).attr('data-price-dealer')) * count,
			price = base_price,
			price_dealer = base_price_dealer;

		if (discount && discount !== 0) {
			discount_val = base_price / 100 * discount;
			price -= discount_val;

			discount_val_dealer = base_price_dealer / 100 * discount;
			price_dealer -= discount_val_dealer;
		}

		all_price += price+delivery_cost;
		all_price_dealer += price_dealer+delivery_cost;

		item.find('.js-cart-base-price-dealer').text(num_format(base_price_dealer));
		item.find('.js-cart-base-price').text(num_format(base_price));
		item.find('.js-cart-price').text(num_format(price));
		item.find('.js-cart-price-dealer').text(num_format(price_dealer));
		item.find('.js-cart-discount').text(num_format(discount_val));
		item.find('.js-cart-discount-dealer').text(num_format(discount_val_dealer));
	});

	$('.js-cart-info-count').text(items.length);
	$('.js-all-cart-price').text(num_format(all_price));
	$('.js-all-cart-price-dealer').text(num_format(all_price_dealer));
	$('.js-all-cart-price-nds').text(num_format(all_price / 100 * nds));
        $('.js-delivery-cost').text(num_format(delivery_cost))
}
