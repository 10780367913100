$(function () {

	if ($('.lk-start-slider').length > 0) {
		let start_slider = $('.lk-start-slider').owlCarousel({
			items: 1,
			nav: 0,
			dots: 1
		});

		$('.js-owl-next').on('click', function () {
			start_slider.trigger('next.owl.carousel');
		})

		$('.sidebar-nav__link--expand').on('click', function () {
			setTimeout(function () {
				start_slider.trigger('refresh.owl.carousel');
			}, 300);
		});
	}
});