
import "../functions/getCookie";
import {getCookie} from '../functions/getCookie';

$(function () {
  const start_remain = 60;

  $('.auth-modal-nav li').on('click', function () {
    let index = $(this).index(),
      content = $(this).closest('.auth-modal').find('.auth-modal-content');


    $(this).siblings().removeClass("active");
    $(this).addClass("active");

    content.removeClass("active");
    content.eq(index).addClass("active");

  });
  var remain_interval;

  if ($('.js-code-timeout').length > 0) {
    var remain = getCookie('code-remain');
    if (remain === '') remain = start_remain;

    remain_interval = setInterval(time_tick, 1000);

  }

  $('.js-send-code').on('click', function () {
    clearInterval(remain_interval);
    remain = start_remain;
    remain_interval = setInterval(time_tick, 1000);
    $('.send-code-block').removeClass("show-btn");
    $('.js-code-timeout').text('00:' + number_format(remain));

    return false;
  });

  function time_tick() {
    remain--;

    if (remain <= 0) {
      clearInterval(remain_interval);
      $('.send-code-block').addClass("show-btn");
    }

    $('.js-code-timeout').text('00:' + number_format(remain));
  }

  function number_format(num) {
    return num < 10 ? '0' + num : num;
  }

});
