import {cart_calc} from "./cart_calc";

export const add_cart = (id, name, img, discount, price, price_dealer, count) => {
	let itm = $('.js-cart-product[data-id="' + id + '"]'),
		exist = itm.length > 0;

	if (exist) {
		let count_block = itm.find('.counter input'),
			count_prev = parseInt(count_block.val());

		count_block.val(count_prev + count);
		cart_calc();
		return true;
	}

	$('.js-cart-table tbody').append('<tr class="js-cart-product" data-discount="' + discount + '" data-id="' + id + '" data-price="' + price + '" data-price-dealer="' + price_dealer + '">' +
		'                                        <td>' +
		'                                            <a class="order-detail-table__link" href="/" target="_blank">' +
		'                                                <img alt="" class="order-detail-table__img" src="' + img + '" loading="lazy">' +
		'' +
		'                                                <div class="order-detail-table__name">' +
		name +
		'                                                </div>' +
		'                                            </a>' +
		'                                        </td>' +
		'                                        <td class="td-nowrap td-hide">' +
		'                                            <div class="counter">' +
		'                                                <input type="text" value="' + count + '">' +
		'                                                <div class="counter-btn js-counter-up"></div>' +
		'                                                <div class="counter-btn js-counter-down"></div>' +
		'                                            </div>' +
		'                                        </td>' +
		'                                        <td class="td-nowrap td-price"><span class="js-cart-price"></span> ₽</td>' +
		'                                        <td class="td-nowrap">' +
		'                                            <a class="cart-remove js-cart-remove" href="#">' +
		'                                                <svg>' +
		'                                                    <use xlink:href="/skins/img/sprite.svg#trash"></use>' +
		'                                                </svg>' +
		'                                            </a>' +
		'                                        </td>' +
		'                                    </tr>')

	cart_calc();
}