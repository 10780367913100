$(function () {

  $('.js-avatar-menu-btn').on('mouseenter', function () {
    $('.avatar-menu').addClass("active");
  });

  $('.header').on('mouseleave', function () {
    $('.avatar-menu').removeClass("active");
  });

});
