function createCustomSelect() {
	$('.js-select:not(.init)').each(function (i, select) {
		wrap_select($(this), select);
	});
	$('.bx-filter-rows select:not(.init)').each(function (i, select) {
		wrap_select($(this), select);
	});
}

function wrap_select(itm, select) {
	itm.wrapAll('<div class="js-select-wrap"></div>');

	if (itm.next().hasClass('custom-select')) {
		return true;
	}

	itm.addClass("init");

	itm.after('<div class="custom-select"><div class="custom-select__single"><span class="custom-select__single-label"></span>' +
		'</div><div class="custom-select__dropdown"><ul class="custom-select__dropdown-list"></ul></div><span class="custom-select-result"></span></div>');

	let dropdown = itm.next();
	let wrap = itm.parent();
	let options = $(select).find('option');
	let selected = itm.find('option:selected');
	let list = dropdown.find('.custom-select__dropdown-list');
	let val = selected.val();

	if (!val || val == '' || val == undefined) {
		dropdown.find('.custom-select__single').addClass('placeholder');
		wrap.removeClass('select');
	} else {
		dropdown.find('.custom-select__single').removeClass('placeholder');
		wrap.addClass('select');
	}

	let color_tag = '';

	if (selected.attr('data-bg')) {
		color_tag = '<span class="round-dot" style="background-color: ' + selected.attr('data-bg') + ';"></span>';
	}

	//dropdown.find('.custom-select__single-label').html(color_tag + selected.html());

	options.each(function (j, o) {
		let display = $(o).data('note') || '';
		color_tag = '';
		if ($(o).attr('data-bg')) {
			color_tag = '<span class="round-dot" style="background-color: ' + $(o).attr('data-bg') + ';"></span>';
		}
		let label = $(o).attr('data-label');
		if (label) {
			list.append('<li class="custom-select__dropdown-item ' + ($(o).is(':selected') ? 'is-active' : '') + ' ' + ($(o).val() == '' ? 'placeholder-itm' : '') + '"' + (display ? 'data-note="' + display + '"' : '') + ' data-bg="' + $(o).attr('data-bg') + '" data-value="' + $(o).val() + '">' +
				'<span class="status-label status-label--' + label + '">' + $(o).html() + '</span>' +
				'</li>')
			return;
		}

		list.append('<li class="custom-select__dropdown-item ' + ($(o).is(':selected') ? 'is-active' : '') + ' ' + ($(o).val() == '' ? 'placeholder-itm' : '') + '"' + (display ? 'data-note="' + display + '"' : '') + ' data-bg="' + $(o).attr('data-bg') + '" data-value="' + $(o).val() + '"><span>' + color_tag + $(o).html() + '</span></li>')
	});

	select_click(list.find('.custom-select__dropdown-item').eq(selected.index()));
}

// Event listeners

$(document).click(function (e) {
	if ($(e.target).closest(".js-select-wrap").length ||
		$(e.target).closest(".fake-select").length) {
		// клик внутри элемента
		return;
	}
	// клик снаружи элемента
	$(".custom-select, .fake-select").removeClass('is-open');
});

// Open/close
$(document).on('click', '.custom-select', function () {
	$('.custom-select').not($(this)).removeClass('is-open');
	$(this).toggleClass('is-open')
	if ($(this).hasClass('is-open')) {
		$(this).find('.custom-select__dropdown-item').attr('tabindex', 0);
		$(this).find('.is-active').focus();

		let table = $(this).closest('.dataTable');

		let drop = $(this).find('.custom-select__dropdown');
		if (table.length > 0) {

			let drop_end = drop.outerHeight(true);
			let table_end = 0,
				prev_height = 0,
				tr = $(this).closest('tr');

			tr.nextAll().each(function () {
				table_end += $(this).outerHeight(true);
			});
			tr.prevAll().each(function () {
				prev_height += $(this).outerHeight(true);
			});

			if (drop_end > table_end && tr.prevAll().length >= 3) {
				drop.addClass('open-top');
			} else {
				drop.removeClass('open-top');
			}

			table.scrollTop(0);
		}

		$(this).addClass('init');

	} else {
		$(this).find('.custom-select__dropdown-item').removeAttr('tabindex');
		$(this).focus()
	}
});

// Option click
$(document).on('click', '.custom-select__dropdown-item', function () {
	select_click($(this));
});

function select_click(itm) {
	itm.closest('.custom-select__dropdown').find('.is-active').removeClass('is-active');
	itm.addClass('is-active');


	let text = itm.html(),
		parent = itm.closest('.js-select-wrap');

	let color_tag = '';
	if (itm.attr('data-bg') && itm.attr('data-bg') != "undefined") {
		color_tag = '<span class="round-dot" style="background-color: ' + itm.attr('data-bg') + ';"></span>';
	}

	itm.closest('.custom-select').find('.custom-select__single-label').html(color_tag + text);
	itm.closest('.custom-select').prev('select').val(itm.data('value')).trigger('change').trigger('click').trigger('input');


	let val = itm.data('value');

	if (!val || val == '') {
		itm.closest('.custom-select').find('.custom-select__single').addClass('placeholder');
		parent.removeClass('select');
	} else {
		itm.closest('.custom-select').find('.custom-select__single').removeClass('placeholder');
		parent.addClass('select');
	}
}

// Keyboard events
$(document).on('keydown', '.custom-select', function (event) {
	let focused_option = $($(this).find('.custom-select__dropdown-item:focus')[0] || $(this).find('.custom-select__dropdown-item.is-active')[0]);
	// Space or Enter
	if (event.keyCode == 32 || event.keyCode == 13) {
		if ($(this).hasClass('is-open')) {
			focused_option.trigger('click')
		} else {
			$(this).trigger('click')
		}
		return false
		// Down
	} else if (event.keyCode == 40) {
		if (!$(this).hasClass('is-open')) {
			$(this).trigger('click')
		} else {
			focused_option.next().focus()
		}
		return false
		// Up
	} else if (event.keyCode == 38) {
		if (!$(this).hasClass('is-open')) {
			$(this).trigger('click')
		} else {
			let focused_option = $($(this).find('.custom-select__dropdown-item:focus')[0] || $(this).find('.custom-select__dropdown-item.is-active')[0]);
			focused_option.prev().focus()
		}
		return false
		// Esc
	} else if (event.keyCode == 27) {
		if ($(this).hasClass('is-open')) {
			$(this).trigger('click');
		}
		return false
	}
});

$(document).ready(function () {
	createCustomSelect()
});

$(document).on('change', '.fake-select input', function () {
	let parent = $(this).closest(".fake-select");

	if ($(this).attr('type') === 'radio') {
		parent.removeClass('is-open');
		parent.find('.fake-select__label').text($(this).parent().text());
		return;
	}

	let text = [];
	parent.find('input:checked').each(function () {
		text.push($(this).parent().text());
	});
	parent.find('.fake-select__label').text(text.join(','));

});

$(document).on('click', '.fake-select__label', function () {

	$('.fake-select').not($(this).closest('.fake-select')).removeClass("is-open");
	$(this).closest('.fake-select').toggleClass("is-open");
})

function select_add(select, name, value) {
	let parent = select.closest('.select');

	select.append('<option value="' + value + '" selected>' + name + '</option>');
	parent.find('.custom-select__single-label').html(name);
	parent.find('.custom-select__dropdown-item').removeClass("is-active");
	parent.find('.custom-select__dropdown-list')
		.append('<li class="custom-select__dropdown-item is-active" data-value="' + value + '"><span>' + name + '</span></li>');
}

