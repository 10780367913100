const sidebarLinks = document.querySelectorAll(".sidebar-nav__link");
const sidebarLinkExp = document.querySelector(".sidebar-nav__link--expand");
const pageBody = document.querySelector(".page__body");
const sidebarWrapper = document.querySelector(".sidebar-wrapper");
const sidebar = document.querySelector(".sidebar");
const sidebarTitle = document.querySelector(".sidebar-nav__title--change");
const expandSvg = document.querySelector(".expand-svg");
const collapseSvg = document.querySelector(".collapse-svg");
const headerBtn = document.querySelector(".header-gamburger");

if (sidebarLinks)
  sidebarLinks.forEach((link) => {
    link.addEventListener("click", function () {
      sidebarLinks.forEach((el) => {
        el.classList.remove("sidebar-nav__link--active");
        this.classList.add("sidebar-nav__link--active");
      });
    });
  });

if (sidebarLinkExp)
  sidebarLinkExp.addEventListener("click", (e) => {
    sidebarLinkExp.classList.toggle("expanded");
    pageBody.classList.toggle("expanded");

    sidebarTitle.textContent = "Свернуть\u00A0меню";
    if (!sidebarLinkExp.classList.contains("expanded")) {
      sidebarTitle.textContent = "Развернуть\u00A0меню";
    }

    expandSvg.classList.toggle("replaced-svg");
    collapseSvg.classList.toggle("replaced-svg");

    sidebarWrapper.classList.toggle("expanded");
    sidebar.classList.toggle("expanded");
    sidebarWrapper.classList.remove("hover");

    setTimeout(() => {
      const tableCatalog = document.querySelector(".dataTables_wrapper");
      tableCatalog?.classList.toggle("collapse");
    }, 0);
  });

if (headerBtn)
  headerBtn.addEventListener("click", (e) => {
    document.querySelector(".header-gamburger .gamburger").classList.toggle("active");
    sidebarWrapper.classList.toggle("menu-open");
    pageBody.classList.toggle("menu-open");
    headerBtn.classList.toggle("menu-open");
  });

if (sidebarWrapper) {
  sidebarWrapper.onmouseenter = function () {
    mouseEnter();
  };
  sidebarWrapper.onmouseleave = function () {
    mouseLeave();
  };
}

function mouseEnter() {
  sidebarWrapper.classList.add("hover");
}

function mouseLeave() {
  sidebarWrapper.classList.remove("hover");
}
