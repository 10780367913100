import {toggleRefinement} from 'instantsearch.js/es/widgets';

export const widgetToggleRefinement = (title, container, attribute) => {
	return toggleRefinement({
                container: container,
                attribute: attribute,
                on:1,
                off:0
            }
	);
}
