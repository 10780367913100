import GraphTabs from "graph-tabs";

if (document.querySelector(`[data-tabs="decors"]`)) {
  const tabs = new GraphTabs("decors");

  const tabsNav = document.querySelectorAll(".tabs__nav-item");

  tabsNav.forEach((item) => {
    item.addEventListener("click", function () {
      tabsNav.forEach((el) => {
        el.classList.remove("tabs__nav-item--active");
        item.classList.add("tabs__nav-item--active");
      });
    });
  });
}
