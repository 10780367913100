import DataTable from "datatables.net-dt";
import "jquery.fancytree/dist/modules/jquery.fancytree.filter";
var partner_id = $('#partner_id').val();
var dataTableJsonFile;
let table_id = "#order-table"
var data_file_url = 'https://lk-v2.vkvadrate.ru/api/get-data-orders/';
if(partner_id===undefined){
    data_file_url = "https://lk-v2.vkvadrate.ru/api/get-data-orders/";
}
else{
    data_file_url = "https://lk-v2.vkvadrate.ru/api/get-data-orders/?key=GEr5v6yjGhj&type=orders&partner_id="+partner_id;
}
console.log('data_file_url: '+data_file_url);
load_data_json(data_file_url);


let table = $(table_id).DataTable({
	info: false,
	pagingType: "numbers",
	pageLength: 20,
	language: {
		url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/ru.json',
	},
	columns: [
                {data: 'id'},
		{data: 'name'},
		{data: 'status_html'},
		{data: 'date'},
		{data: 'shop'},
		{data: 'delivery'},
		{data: 'sum'},
		{data: 'detail_link', className: "js-link-url"},
	],
});


$(function () {

	$('.js-accordion-filter').on('change', function () {
		order_filter_reload()
	});

	$(".js-table-search").on('input', function () {
		let val = this.value;

		table.search(val).draw();
	});

	$(document).on('click', '.js-tr-link-table tr', function () {
		let href = $(this).find('.js-link-url').text();

		if (!href) return;

		window.location.href = href;
	});

	$('.js-accordion-check-all').on('change', function () {
		let checked = $(this).prop('checked');

		$('.js-accordion-filter').prop('checked', checked);
		order_filter_reload();
	});

})

function order_filter_reload() {
	let filter = [];

	$('.js-accordion-filter:checked').each(function () {
		filter.push($(this).val());
	})

	order_table_filter(filter);
}

function load_data_json(data_file_url) {
	$.ajax({
		url: data_file_url,
		success: function (data) {
			dataTableJsonFile = data.data;
			table_init(dataTableJsonFile);
		}
	},)
}

function table_init(data) {
	table.clear().rows.add(data).draw();
}

function order_table_filter(filter) {
	let filterData = dataTableJsonFile.filter(function (value, index) {
		return filter.includes(value.status);
	});
	table_init(filterData);
}
