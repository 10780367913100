import {rangeSlider, rangeInput} from 'instantsearch.js/es/widgets';

export const widgetPrice = (container, attribute) => {

	return rangeSlider({
			container: container,
			attribute: attribute,
			pips: false,
			/*tooltips: {
				format(value) {
					return `${Math.round(value).toLocaleString()}`;
				},
			},*/
		}
	);
}