import {validate_form} from "../functions/validate_form";
import {cart_calc} from "../functions/cart_calc";


$(function () {
	cart_calc();

	$('.cart-content .counter input').on('input', function () {
		cart_calc();
	});
	$('.cart-content .js-counter-up, .cart-content .js-counter-down').on('click', function () {
		cart_calc();
	});
	$(document).on('click', '.js-cart-remove', function () {
		let id = $(this).closest('.js-cart-product').attr('data-id');

		$('.js-cart-product[data-id=' + id + ']').remove();

		if ($('.js-cart-product').length === 0) {
			$('.cart-product-content').html('<p class="h5 c-gray margin-title">В настоящий момент корзина пуста</p>')
			$('.js-cart-btn').addClass("disabled");
		}

		cart_calc();
	});
});

$('.cart-step .required input').on('input', function () {
	if (validate_form($(this).closest('.cart-step'), false)) {
		next_step();
	}
});

function next_step() {
	let active = $('.cart-step.active').last(),
		next = active.next('.cart-step');

	if (next.length === 0) {

	}
	next.slideDown(400, function () {
		next.addClass('active');
	});
}

