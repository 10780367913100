export function validate_form(form, show_error = false) {
	let inputs = form.find('.required input, .required textarea'),
		valide = true;

	if (show_error) {
		form.find('.input').removeClass("error");
	}

	inputs.each(function () {
		let input = $(this);

		if ($(this).val().length === 0 && $(this).parent().find(':-webkit-autofill').length === 0) {
			valide = false;
			if (show_error)
				$(this).closest('.input').addClass("error");
		}
		if ((input.attr('type') === 'checkbox') && input.prop('checked') === false) {
			valide = false;
			if (show_error) {
				input.siblings('.input-hint').text('Поле обязательное для заполнение');
				input.parent().addClass("error");
			}
			return false;
		}

		if (input.attr('type') === 'radio') {
			let name = input.attr('name');

			if ($('input[name="' + name + '"]:checked').length === 0) {
				valide = false;
				return false;
			}
		}
	});

	if (valide) {
		form.find('button').removeClass("disabled");
	} else {
		form.find('button').addClass("disabled");
	}

	return valide;

}