if (
  document.querySelector(".filter__input") ||
  document.getElementById("btnResetSearch")
) {
  const btnResetSearch = document.getElementById("btnResetSearch");
  const filterInput = document.querySelector(".filter__input");

  const descriptorProp = Object.getOwnPropertyDescriptor(
    HTMLInputElement.prototype,
    "value"
  );
  const descriptorAttr = Object.getOwnPropertyDescriptor(
    Element.prototype,
    "setAttribute"
  );

  const onChange = (value) => {
	  if(!btnResetSearch) return;
    if (value !== "") {
      //btnResetSearch.classList.remove("btn__tree--no-active");
      btnResetSearch.classList.add("btn__tree--active");
    } else {
      btnResetSearch.classList.remove("btn__tree--active");
      //btnResetSearch.classList.add("btn__tree--no-active");
    }
  };

  Object.defineProperties(filterInput, {
    value: {
      get: descriptorProp.get,
      set(value) {
        onChange(value);
        descriptorProp.set.call(this, value);
      },
    },
    setAttribute: {
      value(attr, value) {
        if (attr === "value") {
          onChange(value);
        }
        descriptorAttr.value.call(this, attr, value);
      },
    },
  });

  filterInput.addEventListener("input", () => onChange(filterInput.value));
}
