$(function () {

	let calc_price = localStorage.getItem('checkbox-calc-price');
	if (calc_price === 'true') {
		$('.js-checkbox-calc-price').prop('checked', true);
		$('body').addClass("show-calc-price");
	}

	$('.js-checkbox-calc-price').on('change', function () {
		if ($(this).prop('checked')) {
			$('body').addClass("show-calc-price");
		} else {
			$('body').removeClass("show-calc-price");
		}
		localStorage.setItem('checkbox-calc-price', $(this).prop('checked'));
	});

	$(document).on('click', '.js-add-fav', function () {
		let tr = $(this).closest('tr');

		if (tr) {
			tr.find('.js-add-fav').toggleClass("show-svg-fill");
		} else {
			$(this).toggleClass("show-svg-fill");
		}

	});

});
