import DataTable from "datatables.net-dt";
import "jquery.fancytree/dist/modules/jquery.fancytree.filter";
import {num_format} from "../functions/num_format";

var dataTableJsonFile, jsonArray, dataTable, initJsonArrayObjects;
let data_file_url = "/dataTable.json";
load_data_json();


$('.data-table').dataTable({
	info: false,
	pagingType: "numbers",
	language: {
		url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/ru.json',
	},
});

$(window).on('resize', function () {
	check_table_scroll();
});

function check_table_scroll() {
	let body = $('.dataTables_scrollBody'),
		head = $('.dataTables_scrollHead');

	if (body.width() <= body.find('table').width()) {
		head.addClass('has-scroll');
	} else {
		head.removeClass('has-scroll');
	}
}

function load_data_json() {
	$.ajax({
		url: data_file_url,
		success: function (data) {
			dataTableJsonFile = data;

			table_init();
		}
	},)
}

function table_init() {
	initJsonArrayObjects = dataTableJsonFile.tableData;

	jsonArray = dataTableJsonFile.tableData;

	jsonArray.forEach((arr) => {
		let keys = [
				'price',
				'price-dealer',
				'm2_in_package',
				'preview',
				'name',
				'product_id'
			],
			attr_string = "";

		keys.forEach((key, i) => {
			attr_string += " data-" + key + "='" + arr[key] + "'";
		})

		arr.price_html = "<span class='table-price-col'><span class='js-base-price'>" + num_format(arr.price) + " ₽</span><span class='js-calc-price'>" + num_format(arr["price-dealer"]) + " ₽</span></span>";

		arr.brand = '<a href="/catalog/brand-info/?brand_id=' + arr.brand_id + '" class="t-cat__link">' + arr.brand + '</a>';
		arr.collection = '<a href="/catalog/collection-info/?collection_id=' + arr.collection_id + '" class="t-cat__link">' + arr.collection + '</a>';

		arr.actions =
			"<div class='t-cat-menu'>" +
			"<div class='t-cat-menu__item'>" +
			"<button class='btn-reset btn__action btn__menu-popup' aria-label='Открыть контекстное меню'>" +
			"<svg class='svg-points'>" +
			"<use xlink:href='img/sprite.svg#points'></use>" +
			"</svg>" +
			"</button>" +
			"</div>" +
			"<div class='t-cat-menu__item'>" +
			"<button class='btn-reset btn__action js-add-fav'>" +
			"<svg class='svg-standart'>" +
			"<use xlink:href='img/sprite.svg#bookmark'></use>" +
			"</svg>" +
			"<svg class='svg-fill'>" +
			"<use xlink:href='img/sprite.svg#bookmark-fill'></use>" +
			"</svg>" +
			"</button>" +
			"</div>" +
			"<div class='t-cat-menu__item'>" +
			"<button class='btn-reset btn__action btn__add-to-cart' data-graph-path='product-1' " + attr_string + ">" +
			"<svg>" +
			"<use xlink:href='img/sprite.svg#shopping-cart-add'></use>" +
			"</svg>" +
			"</button>" +
			"</div>" +
			"</div>";

		arr.context =
			"<span class='popup-menu' role='tooltip'>" +
			"<span class='visually-hidden'>Контекстное меню: </span>" +
			"<div class='popup-menu__item'>" +
			"<a href='#' class='popup-menu__link js-add-fav'>" +
			"<span class='popup-menu__icon'>" +
			"<svg class='svg-standart'>" +
			"<use xlink:href='img/sprite.svg#bookmark'></use>" +
			"</svg>" +
			"<svg class='svg-fill'>" +
			"<use xlink:href='img/sprite.svg#bookmark-fill'></use>" +
			"</svg>" +
			"</span>" +
			"<span class='popup-menu__title'>Добавить в избранное</span>" +
			"</a>" +
			"</div>" +
			"<div class='popup-menu__item'>" +
			"<a href='#' class='popup-menu__link'>" +
			"<span class='popup-menu__icon'>" +
			"<svg>" +
			"<use xlink:href='img/sprite.svg#copy-link'></use>" +
			"</svg>" +
			"</span>" +
			"<span class='popup-menu__title'>Скопировать ссылку</span>" +
			"</a>" +
			"</div>" +
			"<div class='popup-menu__item'>" +
			"<a href='#' class='popup-menu__link'>" +
			"<span class='popup-menu__icon'>" +
			"<svg>" +
			"<use xlink:href='img/sprite.svg#pdf'></use>" +
			"</svg>" +
			"</span>" +
			"<span class='popup-menu__title'>Скачать PDF</span>" +
			"</a>" +
			"</div>" +
			"</span>" +
			"</div>";

		arr.decor =
			"<a href='/catalog/product-info/?product_id=" + arr.product_id + "' class='t-cat__link'>" + arr.decor +
			"<span class='popup-preview' role='tooltip'>" +
			"<span class='visually-hidden'>Контекстное меню: </span>" +
			"<article class='popup-preview__card'>" +
			"<picture>" +
			"<img loading='lazy' src='" + arr.preview + "' class='popup-preview__image' width='120' height='120' alt='Предпоказ товара'>" +
			"</picture>" +
			"</article>" +
			"</span>" +
			"</a>";
	});

	if (document.querySelector("#t-cat")) {
		dataTable = new DataTable("#t-cat", {
			data: jsonArray,
			deferRender: true,
			columns: [
				{data: "brand", id: "brand_id"},
				{data: "collection", id: "collection_id"},
				{data: "code"},
				{data: "decor", id: "name_id"},
				{data: "price_html"},
				{data: "points"},
				{data: "packages"},
				{data: "errors"},
				{data: "actions"},
				{data: "context"},
			],
			paging: true,
			pageLength: 20,
			info: false,
			pagingType: "numbers",
			language: {
				zeroRecords: "нет результатов",
			},
			columnDefs: [
				{
					targets: 8,
					orderable: false,
				},
			],
			initComplete: function (settings, json) {
				check_table_scroll();
			},
		});

		const catalogTable = $("#t-cat").DataTable();

		dataTable.on('draw', function () {
			check_table_scroll();
		});

		$(".filter__input").on("input", function () {
			catalogTable.search(this.value).draw();
		});
	}
}

//fancytree

$(function () {

	$("#tree").fancytree({
		extensions: ["filter"],
		source: {
			url: "/data.json",
		},

		keydown: function (e, data) {
			if (e.which === 13) {
				data.node.toggleSelected();
				return false;
			}
		},

		select: function (event, data) {
			let selected = tree.getSelectedNodes(),
				BrandSelect = [],
				CollectionSelect = [],
				NameSelect = [];

			if (selected.length == 0) {
				return dataTable.clear().rows.add(initJsonArrayObjects).draw();
			}

			selected.forEach(function (itm) {
				let result = itm.data.id;

				if (itm.folder) {
					if (!itm.parent.folder) {
						BrandSelect.push(result);
						return;
					}
					CollectionSelect.push(result);
					return;
				}
				NameSelect.push(result);
			});


			let filter_data = initJsonArrayObjects.filter(function (value) {
				return BrandSelect.indexOf(value.brand_id) !== -1 ||
					CollectionSelect.indexOf(value.collection_id) !== -1 ||
					NameSelect.indexOf(value.name_id) !== -1;
			});

			return dataTable.clear().rows.add(filter_data).draw();
		},

		filter: {
			autoApply: true,
			autoExpand: true,
			counter: false,
			fuzzy: true,
			hideExpandedCounter: true,
			hideExpanders: true,
			highlight: true,
			leavesOnly: true,
			nodata: "Ничего не нашлось",
			mode: "hide",
		},

		activeVisible: true,
		aria: true,
		autoActivate: true,
		autoCollapse: false,
		autoScroll: true,
		clickFolderMode: 4,
		checkbox: true,
		checkboxAutoHide: undefined,
		debugLevel: 4,
		disabled: false,
		focusOnSelect: false,
		escapeTitles: false,
		generateIds: false,
		idPrefix: "ft_",
		icon: true,
		keyboard: true,
		keyPathSeparator: "/",
		minExpandLevel: 1,
		selectMode: 3,
		tabindex: "0",
		titlesTabbable: false,
		tooltip: false,
		lazyLoad: true,
		quicksearch: true,
	});

	var tree = $.ui.fancytree.getTree("#tree");

	$("input[name=search-filter-tree]").on("keyup", function (e) {
		var tree = $.ui.fancytree.getTree(),
			match = $(this).val();

		if (tree) tree.filterBranches(match);

		if ((e && e.which === $.ui.keyCode.ESCAPE) || $.trim(match) === "") {
			$("button#btnResetSearch").trigger("click");
			return;
		}

	});

	$("button#btnResetSearch")
		.click(function (e) {
			$("input[name=search-filter-tree]").val("");
			$("span#matches").text("");
			tree.clearFilter();
			tree.selectAll(false);
			var extraTable = $("#t-cat").DataTable();
			extraTable.search(this.value).draw();

			if (this.value.length === 0) {
				dataTable
					.clear()
					.rows.add(
					dataTableJsonFile.tableData
				)
					.draw();
			}

		});

	$("fieldset input:checkbox").change(function (e) {
		var id = $(this).attr("id"),
			flag = $(this).is(":checked");
		switch (id) {
			case "counter":
			case "hideExpandedCounter":
				tree.options.filter[id] = flag;
				break;
		}
		tree.clearFilter();
		$("input[name=search-filter-tree]").keyup();
	});

	$("#btnToggleExpand").click(function () {
		$("#tree")
			.fancytree("getRootNode")
			.visit(function (node) {
				node.toggleExpanded();
			});
		return false;
	});
});
