setTimeout(() => {
  if (document.querySelector(".btn__menu-popup")) {

    const myFun = () => {
      const scrollBody = document.querySelector(".dataTables_scrollBody");
      const rowsScrollBody = scrollBody?.querySelectorAll("tr");
      const cellsScrollBody = scrollBody?.querySelectorAll("td");

      cellsScrollBody?.forEach((cell) => {
        cell.classList.add("t-cat__cell");
      });

      rowsScrollBody?.forEach((row, index) => {
        if (index !== 0) {
          row.classList.add("t-cat__row");
        }
      });
    };

    myFun();

    document.addEventListener("click", function (e) {
      if (
        e.target.matches(".paginate_button") ||
        e.target.matches(".t-cat__header")
      ) {
        myFun();
      }
    });

    document.addEventListener("click", function (e) {
      if (
        e.target.matches(".btn__menu-popup") ||
        e.target.closest(".btn__menu-popup") ||
        e.target.matches(".dataTables_scrollBody")
      ) {
        const popupMenu = document?.querySelectorAll(".popup-menu");
        const menuLinks = document?.querySelectorAll(".popup-menu__link");

        popupMenu.forEach((item) => {
          if (item.classList.contains("popup-menu--active")) {
            item.classList.remove("popup-menu--active");
          }
        });

        for (let i = 1; i <= 3; i++) {
          popupMenu[popupMenu.length - i].style.top = "-140px";
        }

        let tableCatalogRow = e.target.closest("tr");
        tableCatalogRow.style.position = "relative";
        const popupMenuEl = tableCatalogRow.querySelector(".popup-menu");
        console.log(popupMenuEl);
        popupMenuEl.classList.add("popup-menu--active");

        window.addEventListener("click", (e) => {
          popupMenu.forEach((el) => {
            if (
              !e.target.closest(".btn__menu-popup") &&
              !e.target.closest(".popup-menu")
            ) {
              el.classList.remove("popup-menu--active");
            }
          });
        });

        menuLinks?.forEach((el) => {
          el.addEventListener("click", (e) => {
            e.preventDefault();
            popupMenu?.forEach((el) => {
              el.classList.remove("popup-menu--active");
            });
          });
        });
      }
    });
  }
}, 0);
