import {hierarchicalMenu, panel} from 'instantsearch.js/es/widgets';
import {collapseButtonText} from "../templates/panel";


export const hierarchicalMenuWidget = (container, title) => {

	const hierarchicalMenuPanel = panel({
		templates: {
			header: title,
			collapseButtonText,
		},
		collapsed: () => false,
	})(hierarchicalMenu);

	return hierarchicalMenuPanel({
		container: container,
		templates: {
			item(data, {html}) {
				return html`
					<a class="${data.cssClasses.link}" href="${data.url}">
						<span class="${data.cssClasses.label}">${data.label}</span> <span class="${data.cssClasses.count}">
           					${data.count.toLocaleString()}
          				</span>
					</a>
				`;
			}
		},
		attributes: [
			'hierarchicalCategories.lvl0',
			'hierarchicalCategories.lvl1',
			'hierarchicalCategories.lvl2',
			'hierarchicalCategories.lvl3',
		],
	});
}


