import {rangeInput} from 'instantsearch.js/es/widgets';

export const widgetPriceInput = (container, attribute) => {

	return rangeInput({
			container: container,
			attribute: attribute,
			templates: {
				submitText({cssClasses}, {html}) {
					return html`<span class="">Применить</span>`;
				},
			},
		}
	);
}
