import {add_cart} from "../functions/add_cart";
import {getCookie} from "../functions/getCookie";
import {num_format} from "../functions/num_format";

if (
	$('input[name="input-area"], ' +
		'input[name = "input-package"], ' +
		'.price__dealer, .price__retail, ' +
		'.amount__dealer, .amount__retail, ' +
		'.prod-type__item, ' +
		'.prod-type__item--active, ' +
		'#inputArea, ' +
		'#inputPackage, ' +
		'[data-graph-path], ' +
		'.promo-product-table'
	).length > 0
) {

	let modal = $('[data-graph-target="product-1"]'),
		id = modal.find('[name="product_id"]').val(),
		name = modal.find('[name="name"]').val(),
		preview_img = modal.find('[name="preview"]').val(),
		price = parseFloat($('.graph-modal [name="price"]').val()),
		price_dealer = parseFloat($('.graph-modal [name="price-dealer"]').val()),
		m_in_pack = parseFloat($('.graph-modal [name="m2_in_package"]').val()),
		laying_k = parseFloat(modal.find('[name="laying-type"]:checked').val());


	const inputArea = document.querySelector('input[name="input-area"]');
	const inputPackage = document.querySelector('input[name="input-package"]');
	const dealerAmount = document.querySelector(".amount__dealer");
	const retailAmount = document.querySelector(".amount__retail");


	const inputAreaValidation = document.querySelector("#inputArea");
	const inputPackageValidation = document.querySelector("#inputPackage");

	if(inputAreaValidation) {
		inputAreaValidation.oninput = function () {
			let num = parseInt(this.value.substr(0, 3));

			if (!num) num = 0;
			if (num < 1) num = 1;

			this.value = num;
		};
	}
	if(inputPackageValidation) {
		inputPackageValidation.oninput = function () {
			let num = parseInt(this.value.substr(0, 3));

			if (!num) num = 0;
			if (num < 1) num = 1;

			this.value = num;
		};
	}

	if(inputArea) inputArea.addEventListener("input", () => {
		inputAreaCalc();
	});

	const inputAreaCalc = () => {
		const valueAreaNumber = parseInt(inputArea.value.substr(0, 3));
		const valueAreaStr = ((valueAreaNumber * laying_k) / m_in_pack).toFixed(2);
		const valueAreaResult = Math.ceil(+valueAreaStr);


		console.log(valueAreaNumber);
		console.log(valueAreaStr);
		console.log({valueAreaNumber, laying_k, m_in_pack});
		console.log(valueAreaResult);

		inputPackage.value = valueAreaResult;

		calc_result();
	}


	if(inputPackage) inputPackage.addEventListener("input", () => {
		const valuePackageNumber = parseInt(inputPackage.value.substr(0, 3));
		const valueAreaNumber = valuePackageNumber * m_in_pack * (2 - laying_k);
		const valuePackageStr = (valueAreaNumber).toFixed(2);
		const valuePackageResult = Math.round(+valuePackageStr);

		inputArea.value = valuePackageResult;

		calc_result();
	});


	const productTypeItem = document.querySelectorAll(".prod-type__item");


	productTypeItem.forEach((item) => {
		item.addEventListener("click", (e) => {
			productTypeItem.forEach((el) => {
				el.classList.remove("prod-type__item--active");
				item.classList.add("prod-type__item--active");
			});

			laying_k = parseFloat(modal.find('[name="laying-type"]:checked').val());
			inputAreaCalc();
		});
	});


	const calc_result = () => {
		const count = Number(inputPackage.value);

		dealerAmount.textContent = num_format(price_dealer * count);
		retailAmount.textContent = num_format(price * count);
	}

	const calc_add_cart_modal = () => {
		price = parseFloat($('.graph-modal [name="price"]').val());
		price_dealer = parseFloat($('.graph-modal [name="price-dealer"]').val());
		m_in_pack = parseFloat($('.graph-modal [name="m2_in_package"]').val());
		id = modal.find('[name="product_id"]').val();
		name = modal.find('[name="name"]').val();
		preview_img = modal.find('[name="preview"]').val();

		let price_m2 = (m_in_pack == 0) ? price : price / m_in_pack;
		let price_m2_dealer = (m_in_pack == 0) ? price_dealer : price_dealer / m_in_pack;

		if (m_in_pack == 0) {
			modal.addClass("modal--no-meter");
		} else {
			modal.removeClass("modal--no-meter");
		}

		modal.find('.price__dealer').text(num_format(price_m2_dealer));
		modal.find('.price__retail').text(num_format(price_m2));
		calc_result();
	}


	$(function () {

		$(document).on('click', '[data-graph-path="product-1"]', (e) => {
			let self = $(e.currentTarget),
				modal = $('[data-graph-target="product-1"]'),
				keys = [
					'price',
					'price-dealer',
					'm2_in_package',
					'preview',
					'name',
					'product_id'
				];

			keys.forEach((key, i) => {
				modal.find('[name="' + key + '"]').val(self.attr("data-" + key));
			});

			calc_add_cart_modal();
		});

		$(document).on('click', '.js-add-cart-modal', (e) => {
			let self = $(e.currentTarget),
				count = Number(inputPackage.value),
				sid = getCookie('sid');


			add_cart(id, name, preview_img, 0, price, price_dealer, count);

			setTimeout(() => {
				$('.js-modal-close').trigger('click');
			}, 300)

			xajax_Add_To_Cart('sid=' + sid + '&product_id=' + id + '&amount=' + count + '&cost=' + price_dealer + 'from=cart');
		})
	})

	$(document).on('click', '.js-link-order-modal', (e) => {
		let self = $(e.currentTarget),
			count = Number(inputPackage.value),
			sid = getCookie('sid');

		xajax_Add_To_Cart('sid=' + sid + '&product_id=' + id + '&amount=' + count + '&cost=' + price_dealer + 'from=order');
	});

}


