import {panel, refinementList} from 'instantsearch.js/es/widgets';

import {collapseButtonText} from '../templates/panel';

export const widgetList = (title, container, attribute, search = false, search_placeholder = "", show_more = true) => {
	const RefinementList = panel({
		templates: {
			header: title,
			collapseButtonText,
		},
		collapsed: () => false,
	})(refinementList);
	return RefinementList({
		container: container,
		attribute: attribute,
		showMore: show_more,
		searchable: search,
		searchablePlaceholder: search_placeholder,
		transformItems(items, {results}) {
			if(items.length === 0){
				$(container).addClass("hide");
			}else{
				$(container).removeClass("hide");
			}

			items.forEach((i, k) => {
				let color_ico = results.hits[0].color_icons[i.value];

				items[k]['color_ico'] = color_ico;
			})
			return items;
		},
		templates: {
			item(data, {html}) {
				const {url, label, count, isRefined, color_ico} = data;
				return `
				<label class="ais-RefinementList-label">
					<input type="checkbox" class="ais-RefinementList-checkbox" value="${label}" ${isRefined ? 'checked' : ''}>				
					<span class="ais-RefinementList-labelText">
						${color_ico ? `<span class="ais-RefinementList-labelText__ico"><img src="/skins/img/color-icons/${color_ico}"></span>` : ''}
						<span class="ais-RefinementList-labelText__text">${label}</span>
					</span>
					<span class="ais-RefinementList-count">${count}</span>
				</label>
				`;
			},
			showMoreText(data, { html }) {
				return html`<span>${data.isShowingMore ? 'Показать меньше' : 'Показать больше'}</span>`;
			},
		},
	});
}
