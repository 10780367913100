if (document.querySelector(".btn__decor-popup", ".popup-decor")) {
  const btnDecorPopup = document.querySelector(".btn__decor-popup");
  const popupDecorMenu = document.querySelector(".popup-decor");
  const decorLinks = document?.querySelectorAll(".popup-decor__link");

  btnDecorPopup.addEventListener("click", (e) => {
    popupDecorMenu.classList.toggle("popup-decor--active");
  });

  window.addEventListener("click", (e) => {
    const target = e.target;
    if (
      !target.closest(".popup-decor") &&
      !target.closest(".btn__decor-popup")
    ) {
      popupDecorMenu.classList.remove("popup-decor--active");
    }
  });

  decorLinks?.forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      decorLinksParent = el.closest(".popup-decor");
      decorLinksParent.classList.remove("popup-decor--active");
    });
  });
}
