$(() => {
	init_facet_view();

	$('.facet-accordion-header').on('click', (e) => {
		let self = $(e.currentTarget)

		self.toggleClass('active');
		self.siblings('.facet-body').slideToggle();
	});

	$('.ais-Panel-header').on('click', (e) => {
		let self = $(e.currentTarget)

		self.toggleClass('active');
		self.siblings('.ais-Panel-body').slideToggle();
	});

	$('.js-change-facet-view').on('click', (e) => {
		let self = $(e.currentTarget),
			view = self.attr("data-view");

		localStorage.setItem('facet_view', view);

		location.reload();
	});

	$('.js-search-modal-filter').on('input', (e) => {
		const self = $(e.currentTarget),
			val = self.val().toLowerCase()

		if (val.length === 0) {
			$('.modal-facet-grid [data-widget]').removeClass("hide");
			return true;
		}

		$('.modal-facet-grid .ais-Panel-header').each((k, i) => {
			let text = $(i).text().toLowerCase()
			if (text.indexOf(val) !== -1) {
				$(i).closest('[data-widget]').removeClass("hide");
			} else {
				$(i).closest('[data-widget]').addClass("hide");
			}
		});

	})

	$(document).on('click', '.js-copy-buffer', (e) => {
		let self = $(e.currentTarget),
			text = self.attr('data-text')
		navigator.clipboard.writeText(text).then(function () {
			alert('Текст скопирован в буфер обмена');
		}, function (err) {
			console.error('Ошибка при копировании в буфер обмена: ', err);
			alert('Ошибка при копировании в буфер обмена');
		});

		return false;
	});

})


const init_facet_view = () => {
	let view = localStorage.getItem('facet_view');
	if (!view) {
		view = 'table';
		localStorage.setItem('facet_view', view);
	}

	$(".js-change-facet-view[data-view='" + view + "']").addClass("active");

}